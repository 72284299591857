import * as React from "react";
import {Link, useTranslation} from "gatsby-plugin-react-i18next";
import {Container} from "react-bootstrap";
import {getImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import BlogCard from "./BlogCard";
import Slider from "react-slick";
import {sliderSettings} from "../constants";

export default function HomeBlog() {
    const { t } = useTranslation();
    const { allPostsDataJson } = useStaticQuery(
        graphql`
            query {
              allPostsDataJson(limit: 4) {
                nodes {
                  category
                  h1
                  slug
                  title
                  src {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          `
    );

    return (
        <Container fluid className="px-flex text-center py-md-4 py-xxl-5">
            <h2 className="h1 lined-head mb-3 mb-md-4 text-center mx-4 mx-md-6 mx-xl-7 mt-3 mt-md-4 mt-xxl-5">{t('lastBlogPosts')}</h2>
            <Slider className="blog-posts-slider my-2 my-xl-3 my-xxl-4 pt-1 py-xl-2" {...sliderSettings}>
                {allPostsDataJson.nodes.map((item, index) => {
                    const blogImage = getImage(item.src);

                    return (
                        <BlogCard item={item} blogImage={blogImage} inBlock={true} key={`blog-item-${index}`} />
                    )
                })}
            </Slider>
            <Link to={'/blog'} className="btn__big pointer text-nowrap mb-3 mb-md-4 mb-xxl-5 mt-2 mt-md-3">{t('showMore')}</Link>
        </Container>
    )
}